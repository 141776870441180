<template>
  <div>
    <!-- search input -->
    <section>
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        style="background-color: white !important;"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body
          class="card-body"
          style="padding: 5rem !important;"
        >
          <div style="display: flex; justify-content: end; margin-top: -40px; margin-right: -35px;">
            <b-button
              v-if="permission"
              variant="primary"
              class="btn-icon"
              :to="{ name: 'add-evaluations' }"
            >
              Create Forms
              <feather-icon
                style="margin-left: 5px; margin-bottom: 1px;"
                icon="PlusIcon"
                size="10"
              />
            </b-button>
          </div>
          <h2 class="text-primary mb-1">
            Forms
          </h2>
          <!-- <b-card-text class="mb-2">
            <span>Popular searches: </span>
            <span class="font-weight-bolder">Sales automation, Email marketing</span>
          </b-card-text>-->
          <!-- form -->
          <b-form class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="SearchEvaluations"
                placeholder="Search your Forms..."
                @input="handleSearch"
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->
    <section>
      <!-- content -->
      <b-row>
        <b-col
          v-for="(evaluation, index) in Evaluations"
          :key="index"
          md="6"
          lg="3"
        >
          <b-card
            style="box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important; padding-left: 0px; padding-right: 0px;"
            no-body
          >
            <b-card-body style="padding-bottom: 0px;">
              <b-card-title style="font-size: 15px">
                {{ index }}
              </b-card-title>
            </b-card-body>
            <b-list-group>
              <vue-perfect-scrollbar>
                <b-list-group-item
                  v-for="(evalu, indexi) in evaluation.elements"
                  :key="indexi"
                  class="d-flex justify-content-start align-items-center"
                  flush
                  style="border: none !important;"
                  @click="navigateToDash(evalu.Id)"
                >
                  <div>
                    <b-avatar
                      rounded
                      size="18"
                      variant="light-success"
                      class="mr-75"
                    >
                      <feather-icon
                        icon="AwardIcon"
                        size="10"
                      />
                    </b-avatar>
                  </div>
                  <div>
                    <div
                      class="text-body"
                      style="font-size: 12px;"
                    >
                      {{ evalu.EvalName }}
                    </div>
                  </div>
                </b-list-group-item>
              </vue-perfect-scrollbar>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BAvatar, BCardTitle, BButton, VBToggle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import evaluationsService from '@core/services/evaluations/evaluationService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BAvatar,
    BCardTitle,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      SearchEvaluations: '',
      Evaluations: [],
      permission: true,
    }
  },
  mounted() {
    this.getEvaluations()
    this.getPermission()
  },
  methods: {
    navigateToDash(id) {
      this.$store.commit('evaluations/setIdEvaluation', id)
      this.$router.push({ name: 'evaluation-dashboard' })
    },
    getPermission() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile !== 1) {
        this.permission = false
      }
    },
    getEvaluations() {
      this.$store.commit('evaluations/setIdEvaluation', null)
      evaluationsService.getEvalutionsByCategory({
        catName: this.SearchEvaluations,
      }).then(response => {
        this.Evaluations = response.data.data
      })
    },
    handleSearch(searching) {
      this.SearchEvaluations = searching
      this.getEvaluations()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.anotherscrollvueheight{
  height: 70px !important;
}

.ps-container {
  height: 180px;
}
</style>
